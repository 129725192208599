var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{class:[_vm.isMobileScreen ? '' : 'vms-content__container'],attrs:{"index-name":"videos_index","search-client":_vm.searchClient,"initial-ui-state":_vm.initialUiState,"middlewares":_vm.middlewares}},[_c('ais-configure',{attrs:{"filters":_vm.filters,"hitsPerPage":_vm.defaultHitsPerPage,"maxValuesPerFacet":_vm.defaultMaxValuesPerFacet}}),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"vms-video__library--section-left"},[_c('div',{staticClass:"vd-padding-20"},[_c('div',{staticClass:"vd-h4 vms-video__filters--divider vd-border-light-grey vd-padding-bottom-medium vd-margin-bottom-medium"},[_c('strong',[_vm._v("Related videos by:")]),_c('div',{staticClass:"vd-margin-top-medium"},[_c('ais-toggle-refinement',{attrs:{"attribute":"project_id","on":_vm.video.project_id,"label":"Project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var refine = ref.refine;
return [_c('filter-checkbox',{attrs:{"label":"Project","value":_vm.video.project_id,"checked":value.isRefined,"count":value.onFacetValue.count},on:{"handle:checkbox-click":function($event){return refine(value)}}})]}}])}),_c('ais-toggle-refinement',{attrs:{"attribute":"office_name","on":_vm.video.office_name,"label":"Company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var refine = ref.refine;
return [_c('filter-checkbox',{attrs:{"label":"Company","value":_vm.video.office_name,"checked":value.isRefined,"count":value.onFacetValue.count},on:{"handle:checkbox-click":function($event){return refine(value)}}})]}}])}),(_vm.video.video_style)?_c('ais-toggle-refinement',{attrs:{"attribute":"video_style","on":_vm.video.video_style,"label":"Video Style"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var refine = ref.refine;
return [_c('filter-checkbox',{attrs:{"label":"Video Style","value":_vm.video.video_style,"checked":value.isRefined,"count":value.onFacetValue.count},on:{"handle:checkbox-click":function($event){return refine(value)}}})]}}],null,false,1832878778)}):_vm._e(),_c('ais-toggle-refinement',{attrs:{"attribute":"product_type","on":_vm.video.product_type,"label":"Product Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var refine = ref.refine;
return [_c('filter-checkbox',{attrs:{"label":"Product Type","value":_vm.video.product_type,"checked":value.isRefined,"count":value.onFacetValue.count},on:{"handle:checkbox-click":function($event){return refine(value)}}})]}}])}),(_vm.video.industry_type)?_c('ais-toggle-refinement',{attrs:{"attribute":"industry_type","on":_vm.video.industry_type,"label":"Video Industry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var refine = ref.refine;
return [_c('filter-checkbox',{attrs:{"label":"Video Industry","value":_vm.video.industry_type,"checked":value.isRefined,"count":value.onFacetValue.count},on:{"handle:checkbox-click":function($event){return refine(value)}}})]}}],null,false,3848342579)}):_vm._e()],1)])])]),_c('div',{staticClass:"vms-video__library--section-right"},[(_vm.isAiHitsLoading)?_c('div',[_c('default-loader')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAiHitsLoading),expression:"!isAiHitsLoading"}],staticClass:"vd-padding-20"},[_c('div',{staticClass:"vd-full-width vd-margin-bottom-medium"},[_c('ais-panel',[_c('ais-refinement-list',{attrs:{"attribute":"public_video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return [_c('video-privacy',{attrs:{"items":items},on:{"handle:toggle-privacy":function($event){return refine(0)}}})]}}])})],1)],1),_c('div',{staticClass:"vd-margin-bottom-medium"},[_c('ais-hits',{attrs:{"transform-items":_vm.transformItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var sendEvent = ref.sendEvent;
return [(!items.length)?_c('no-results',[_c('div',{attrs:{"slot":"empty-content"},slot:"empty-content"},[_c('div',{staticClass:"vd-margin-bottom-10"},[_vm._v(" Uh oh, nothing came up. ")]),_c('div',{staticClass:"vd-margin-bottom-10"},[_vm._v(" Have you tried looking under private videos? ")])])]):_c('video-thumbnail',{attrs:{"videos":items},on:{"handle:project-click":function($event){return sendEvent('conversion', $event, 'Item Project Clicked')},"handle:copy-url":function($event){return sendEvent('conversion', $event, 'Item Recommended')},"handle:vlp-click":function($event){return sendEvent('conversion', $event, 'Item VLP Clicked')},"handle:copy-private":function($event){return sendEvent('conversion', $event, 'Item Shared')},"handle:item-click":function($event){return sendEvent('click', $event, 'Item Clicked')}}})]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }