<template>
  <div
    :class="[
      'vms-video__results--media',
      isMobileScreen ? 'vms-video__results--media__mobile' : '',
    ]"
    @mouseover="setOnHoverState(true)"
    @mouseleave="setOnHoverState(false)"
  >
    <div v-if="onHoverState && item.mp4_url" class="vms-video__results--video">
      <video-player
        :video-options="videoOptions"
        :video-object-id="item.objectID"
        :video-query-id="item.__queryID"
        :track-algolia-analytics="true"
      ></video-player>
    </div>

    <!-- Can't use v-custom-background-image because it is not reactive -->
    <div
      v-else
      class="vms-video__results--image"
      :style="{ 'background-image': `url(${item.thumbnail_url})` }"
    ></div>
  </div>
</template>

<script>
import VideoPlayer from '@components/video-player/video-player'
import { mapGetters } from 'vuex'

export default {
  components: {
    VideoPlayer,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      onHoverState: false,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
    videoOptions() {
      return {
        controls: true,
        controlBar: {
          children: {
            playToggle: {},
            progressControl: {},
            VolumePanel: {},
          },
        },
        sources: [
          {
            src: this.item.mp4_url,
            type: 'video/mp4',
          },
        ],
        aspectRatio: '16:9',
        preload: true,
        autoplay: true,
        muted: true,
        userActions: {
          hotkeys: false,
        },
      }
    },
  },

  methods: {
    /**
     * Set hover state
     *
     * @param {Boolean} state
     */
    setOnHoverState(state) {
      this.onHoverState = state
    },
  },
}
</script>
