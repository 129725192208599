var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-modal',{staticClass:"show",attrs:{"modal-dialog-class":[
    'modal-lg vms-form-modal__dialog',
    _vm.isMobileScreen ? 'vms-form-modal__dialog--mobile vd-margin-0' : '' ],"content-class":"vd-background-white"}},[_c('div',{staticClass:"modal-header vms-form-modal__header vd-background-white",attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('div',{staticClass:"vd-h4 vd-darker-grey"},[_vm._v("Video Information")]),_c('div',{staticClass:"vms-modal__header--close close vd-black"},[_c('span',{attrs:{"id":"closeModalSpan"},on:{"click":function($event){$event.preventDefault();return _vm.toggleVideoInformationShowModal($event)}}},[_vm._v("x")])])]),_c('div',{staticClass:"vms-form-modal__body",attrs:{"slot":"modal-body"},slot:"modal-body"},[(_vm.isVideoInfoLoading)?_c('div',{staticClass:"vms-form-modal__loader"},[_c('default-loader')],1):_c('div',[_c('div',{class:[
          'vd-padding-top-medium vd-padding-bottom-large',
          _vm.isMobileScreen
            ? 'vd-padding-left-large vd-padding-right-large '
            : 'vd-padding-left-extra-large vd-padding-right-extra-large' ]},[_c('div',{staticClass:"vd-padding-bottom-medium d-flex justify-content-center"},[_c('div',{staticClass:"vms-form-modal__video-player"},[_c('video-player',{attrs:{"video-options":_vm.videoOptions}})],1)]),_c('v-app',[_c('div',{staticClass:"row"},[_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('div',{staticClass:"vd-padding-bottom-20 vd-padding-top-20"},[_c('div',{class:[
                    'vd-field',
                    _vm.selectedVideoIndustry ? 'vd-input-valid' : '' ]},[_c('label',{attrs:{"for":"deliveriesVideoIndustrySelect"}},[_vm._v("Video Industry")]),_c('model-list-select',{attrs:{"id":"deliveriesVideoIndustrySelect","name":"deliveriesVideoIndustrySelect","list":_vm.videoIndustryOptions,"option-value":"id","option-text":"label","placeholder":"Select an industry"},model:{value:(_vm.selectedVideoIndustry),callback:function ($$v) {_vm.selectedVideoIndustry=$$v},expression:"selectedVideoIndustry"}})],1)])]),_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('div',{staticClass:"vd-padding-bottom-small vd-padding-top-20"},[_c('v-select',{class:[
                    'vd-field',
                    _vm.selectedVideoStyleTags.length ? 'vd-input-valid' : '' ],attrs:{"id":"videoStyleSelect","label":"Video Styles","items":_vm.videoStyleOptionsFormatted,"placeholder":"Select video styles","persistent-placeholder":"","multiple":"","chips":"","small-chips":"","clearable":"","deletable-chips":true,"hide-selected":true,"no-data-text":"No results to display","menu-props":_vm.videoStyleSelectProps},model:{value:(_vm.selectedVideoStyleTags),callback:function ($$v) {_vm.selectedVideoStyleTags=$$v},expression:"selectedVideoStyleTags"}})],1)]),_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('div',{class:['vd-field', _vm.videoTopic ? 'vd-input-valid' : '']},[_c('label',{staticClass:"vd-dark-grey",attrs:{"for":"videoTopicTextArea"}},[_vm._v("Video Topic")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.videoTopic),expression:"videoTopic"}],attrs:{"id":"videoTopicTextArea"},domProps:{"value":(_vm.videoTopic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.videoTopic=$event.target.value}}})])]),_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('v-combobox',{class:[
                  'vd-field',
                  _vm.selectedVideoTags.length ? 'vd-input-valid' : '' ],attrs:{"label":"Video Tags","items":_vm.videoTagOptionArray,"hide-selected":true,"deletable-chips":true,"loading":_vm.isVideoTagLoading,"placeholder":"Select video tags","persistent-placeholder":"","no-data-text":"Start typing to search for tags","multiple":"","chips":"","small-chips":"","search-input":_vm.videoTagsSearchInput},on:{"update:searchInput":function($event){_vm.videoTagsSearchInput=$event},"update:search-input":function($event){_vm.videoTagsSearchInput=$event}},model:{value:(_vm.selectedVideoTags),callback:function ($$v) {_vm.selectedVideoTags=$$v},expression:"selectedVideoTags"}},[_c('span',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.handleVideoTagsLoadMore),expression:"handleVideoTagsLoadMore"}],attrs:{"slot":"append-item"},slot:"append-item"})])],1),_c('div',{staticClass:"col-12"},[_c('div',{class:[
                  'vd-field',
                  _vm.videoDescription.length ? 'vd-input-valid' : '' ]},[_c('label',{staticClass:"vd-dark-grey vd-margin-bottom-extra-small",attrs:{"for":"id"}},[_vm._v("Video Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.videoDescription),expression:"videoDescription"}],attrs:{"id":"videoDescriptionTextArea"},domProps:{"value":(_vm.videoDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.videoDescription=$event.target.value}}})])])])])],1)])]),_c('div',{class:[
      'vd-box-shadow vms-form-modal__footer',
      _vm.isMobileScreen
        ? 'vms-form-modal__footer--mobile vd-background-lightest-grey'
        : 'vd-background-white' ],attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('div',{class:[
        _vm.isMobileScreen
          ? 'row no-gutters vd-padding-medium'
          : 'vd-text-align-right vd-padding-left-extra-large vd-padding-right-extra-large vd-padding-top-large vd-padding-bottom-large' ]},[_c('div',{class:[
          _vm.isMobileScreen
            ? 'col-6 vd-text-align-center'
            : 'vd-display-inline-block vd-margin-right-small' ]},[_c('button',{staticClass:"vd-btn-medium",on:{"click":_vm.handleCancel}},[_vm._v("Cancel")])]),_c('div',{class:[
          _vm.isMobileScreen
            ? 'col-6 vd-text-align-center'
            : 'vd-display-inline-block' ]},[_c('button',{staticClass:"vd-btn-medium vd-btn-blue",attrs:{"disabled":_vm.isSaveDisabled},on:{"click":_vm.submitFormUpdate}},[_vm._v(" Save ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }