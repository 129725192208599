<template>
  <ais-instant-search
    index-name="videos_index"
    :search-client="searchClient"
    :initial-ui-state="initialUiState"
    :middlewares="middlewares"
    :class="[isMobileScreen ? '' : 'vms-content__container']"
  >
    <ais-configure
      :filters="filters"
      :hits-per-page.camel="defaultHitsPerPage"
      :max-values-per-facet.camel="defaultMaxValuesPerFacet"
    />

    <div class="row no-gutters">
      <div class="vms-video__library--section-left">
        <div class="vd-padding-20">
          <div
            class="vd-h4 vms-video__filters--divider vd-border-light-grey vd-padding-bottom-medium vd-margin-bottom-medium"
          >
            <strong>Related videos by:</strong>

            <div class="vd-margin-top-medium">
              <ais-toggle-refinement
                attribute="project_id"
                :on="video.project_id"
                label="Project"
              >
                <template v-slot="{ value, refine }">
                  <filter-checkbox
                    label="Project"
                    :value="video.project_id"
                    :checked="value.isRefined"
                    :count="value.onFacetValue.count"
                    @handle:checkbox-click="refine(value)"
                  >
                  </filter-checkbox>
                </template>
              </ais-toggle-refinement>

              <ais-toggle-refinement
                attribute="office_name"
                :on="video.office_name"
                label="Company"
              >
                <template v-slot="{ value, refine }">
                  <filter-checkbox
                    label="Company"
                    :value="video.office_name"
                    :checked="value.isRefined"
                    :count="value.onFacetValue.count"
                    @handle:checkbox-click="refine(value)"
                  >
                  </filter-checkbox>
                </template>
              </ais-toggle-refinement>

              <ais-toggle-refinement
                v-if="video.video_style"
                attribute="video_style"
                :on="video.video_style"
                label="Video Style"
              >
                <template v-slot="{ value, refine }">
                  <filter-checkbox
                    label="Video Style"
                    :value="video.video_style"
                    :checked="value.isRefined"
                    :count="value.onFacetValue.count"
                    @handle:checkbox-click="refine(value)"
                  >
                  </filter-checkbox>
                </template>
              </ais-toggle-refinement>

              <ais-toggle-refinement
                attribute="product_type"
                :on="video.product_type"
                label="Product Type"
              >
                <template v-slot="{ value, refine }">
                  <filter-checkbox
                    label="Product Type"
                    :value="video.product_type"
                    :checked="value.isRefined"
                    :count="value.onFacetValue.count"
                    @handle:checkbox-click="refine(value)"
                  >
                  </filter-checkbox>
                </template>
              </ais-toggle-refinement>

              <ais-toggle-refinement
                v-if="video.industry_type"
                attribute="industry_type"
                :on="video.industry_type"
                label="Video Industry"
              >
                <template v-slot="{ value, refine }">
                  <filter-checkbox
                    label="Video Industry"
                    :value="video.industry_type"
                    :checked="value.isRefined"
                    :count="value.onFacetValue.count"
                    @handle:checkbox-click="refine(value)"
                  >
                  </filter-checkbox>
                </template>
              </ais-toggle-refinement>
            </div>
          </div>
        </div>
      </div>

      <div class="vms-video__library--section-right">
        <div v-if="isAiHitsLoading">
          <default-loader></default-loader>
        </div>
        <div v-show="!isAiHitsLoading" class="vd-padding-20">
          <div class="vd-full-width vd-margin-bottom-medium">
            <ais-panel>
              <ais-refinement-list attribute="public_video">
                <template v-slot="{ items, refine }">
                  <video-privacy
                    :items="items"
                    @handle:toggle-privacy="refine(0)"
                  ></video-privacy>
                </template>
              </ais-refinement-list>
            </ais-panel>
          </div>
          <div class="vd-margin-bottom-medium">
            <ais-hits :transform-items="transformItems">
              <template v-slot="{ items, sendEvent }">
                <no-results v-if="!items.length">
                  <div slot="empty-content">
                    <div class="vd-margin-bottom-10">
                      Uh oh, nothing came up.
                    </div>
                    <div class="vd-margin-bottom-10">
                      Have you tried looking under private videos?
                    </div>
                  </div>
                </no-results>
                <video-thumbnail
                  v-else
                  :videos="items"
                  @handle:project-click="
                    sendEvent('conversion', $event, 'Item Project Clicked')
                  "
                  @handle:copy-url="
                    sendEvent('conversion', $event, 'Item Recommended')
                  "
                  @handle:vlp-click="
                    sendEvent('conversion', $event, 'Item VLP Clicked')
                  "
                  @handle:copy-private="
                    sendEvent('conversion', $event, 'Item Shared')
                  "
                  @handle:item-click="
                    sendEvent('click', $event, 'Item Clicked')
                  "
                >
                </video-thumbnail>
              </template>
            </ais-hits>
          </div>
        </div>
      </div>
    </div>
  </ais-instant-search>
</template>

<script>
import VideoThumbnail from '@views/videos/video-thumbnail/video-thumbnail'
import VideoPrivacy from '@views/videos/components/video-privacy'
import FilterCheckbox from '@views/videos/video-filters/filter-checkbox'
import DefaultLoader from '@components/loaders/default-loader'
import { mapGetters } from 'vuex'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import algoliaAnalytics from 'search-insights'

import {
  AisHits,
  AisConfigure,
  AisInstantSearch,
  AisRefinementList,
  AisToggleRefinement,
  AisPanel,
} from 'vue-instantsearch'
import algoliaSearch from '@plugins/algolia'
import NoResults from '@views/videos/components/no-results'

// Don't initialize analytics on test env.
if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

// Do not create insights client for test env.
const algoliaAnalyticsMiddleware = createInsightsMiddleware({
  insightsClient: process.env.NODE_ENV !== 'test' ? algoliaAnalytics : null,
})

export default {
  components: {
    VideoThumbnail,
    VideoPrivacy,
    FilterCheckbox,
    DefaultLoader,
    NoResults,

    // Algolia Components
    AisHits,
    AisConfigure,
    AisInstantSearch,
    AisRefinementList,
    AisToggleRefinement,
    AisPanel,
  },

  props: {
    video: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      defaultHitsPerPage: 40,
      defaultMaxValuesPerFacet: 1000,
      isAiHitsLoading: true,
      middlewares: [algoliaAnalyticsMiddleware],
    }
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
      isMobileScreen: 'common/isMobileScreen',
    }),

    searchClient() {
      return algoliaSearch
    },

    initialUiState() {
      return {
        videos_index: {
          refinementList: {
            public_video: ['0', '1'],
          },
        },
      }
    },

    filters() {
      let filters = [
        `project_id:${this.video.project_id}`,
        `OR product_type:"${this.video.product_type}"`,
        `OR office_name:"${this.video.office_name}"`,
      ]

      if (this.video.video_style) {
        filters.push(`OR video_style:"${this.video.video_style}"`)
      }

      if (this.video.industry_type) {
        filters.push(`OR industry_type:"${this.video.industry_type}"`)
      }

      return `video_id!=${this.video.video_id} AND (${filters.join(' ')})`
    },
  },

  mounted() {
    if (this.loggedInUser.id) {
      algoliaAnalytics('setUserToken', this.loggedInUser.id.toString())
    }
  },

  methods: {
    /**
     * Computes the weighted score of algolia's result
     * and sort it by score and upload timestamp.
     *
     * @param  Array items
     * @return Array
     */
    transformItems(items) {
      this.isAiHitsLoading = false
      items = items
        .map((item) => {
          // Compute the weighted score.
          let score = 0
          if (this.video.project_id === item.project_id) {
            score += 100
          }

          if (this.video.office_name === item.office_name) {
            score += 80
          }

          if (this.video.video_style === item.video_style) {
            score += 60
          }

          if (this.video.product_type === item.product_type) {
            score += 40
          }

          if (this.video.industry_type === item.industry_type) {
            score += 20
          }

          return {
            ...item,
            score,
          }
        })
        // Sort the result by score and uplodate timestamp.
        .sort((a, b) => {
          if (a.score > b.score) {
            return -1
          }

          // if the scores are equal the item will be
          // sorted base on its upload timestamp
          if (
            a.score === b.score &&
            a.uploaded_timestamp > b.uploaded_timestamp
          ) {
            return -1
          }

          return 1
        })

      return items
    },
  },
}
</script>
