var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('videos-layout',[(_vm.video)?_c('div',{class:[
      'vd-background-lightest-grey',
      _vm.isMobileScreen
        ? ''
        : 'vd-margin-bottom-extra-large vms-content__container' ],attrs:{"slot":"content-header"},slot:"content-header"},[_c('video-breadcrumbs',{staticClass:"vd-padding-left-medium",attrs:{"video":_vm.video}}),_c('div',{class:[
        _vm.isMobileScreen
          ? 'vd-padding-top-medium'
          : 'vms-video__preview vd-padding-extra-large vd-padding-bottom-70' ]},[_c('div',{class:[_vm.isMobileScreen ? '' : 'd-xl-flex justify-content-xl-center']},[_c('div',{class:[_vm.isMobileScreen ? '' : 'col-12 col-xl-6']},[_c('div',{class:[
              'vms-video__preview--video',
              _vm.isMobileScreen ? '' : 'vd-padding-right-medium' ]},[_c('video-player',{attrs:{"video-options":_vm.videoOptions,"video-object-id":_vm.video.objectID,"video-query-id":_vm.videoQueryId,"track-algolia-analytics":true}})],1)]),_c('video-preview-description',{staticClass:"col-auto",attrs:{"video":_vm.video}})],1)])],1):_vm._e(),(_vm.video)?_c('related-videos',{attrs:{"slot":"content-body","video":_vm.video},slot:"content-body"}):_vm._e(),(!_vm.video)?_c('div',{attrs:{"slot":"content-body"},slot:"content-body"},[_c('default-loader',{staticClass:"vms-loader__visdom-shield--full"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }