var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vms-video__preview--title"},[_c('span',{class:[
        'vms-video__preview--icons-privacy vd-margin-right-small',
        _vm.video.public_video ? 'vd-icon-video-public' : 'vd-icon-video-private',
        _vm.isMobileScreen ? 'vms-video__preview--icons-privacy__mobile' : '' ]}),_c('span',{class:[
        'vms-video__preview--title-text',
        _vm.isMobileScreen ? 'vd-h3' : 'vd-h2' ]},[_vm._v(" "+_vm._s(_vm._f("truncateString")(_vm.video.video_title,65))+" ")])]),_c('div',{staticClass:"vd-h5 vd-margin-top-small"},[_c('span',[_vm._v(" Date created: "+_vm._s(_vm._f("unixToFormat")(_vm.video.uploaded_timestamp,'D/M/YYYY'))+" ")])]),_c('div',{staticClass:"vd-margin-top-small"},[_c('p',[_vm._v(_vm._s(_vm._f("truncateString")(_vm.video.description,250)))])]),(_vm.canDisplaySubInfo)?_c('div',{staticClass:"vd-margin-top-small"},[(_vm.video.office_name)?_c('div',[_c('span',[_vm._v("Office: ")]),_c('router-link',{attrs:{"to":{
          name: 'videos.library',
          query: { 'office_name[0]': _vm.video.office_name },
        }}},[_c('span',{staticClass:"text-decoration-underline vd-black"},[_vm._v(" "+_vm._s(_vm.video.office_name)+" ")])])],1):_vm._e(),(_vm.video.product_type)?_c('div',[_c('span',[_vm._v("Product Type: ")]),_c('router-link',{attrs:{"to":{
          name: 'videos.library',
          query: { 'product_type[0]': _vm.video.product_type },
        }}},[_c('span',{staticClass:"text-decoration-underline vd-black"},[_vm._v(" "+_vm._s(_vm.video.product_type)+" ")])])],1):_vm._e(),(_vm.video.project_account_manager)?_c('div',[_c('span',[_vm._v("Relationship Manager: ")]),_c('router-link',{attrs:{"to":{
          name: 'videos.library',
          query: {
            'project_account_manager[0]': _vm.video.project_account_manager,
          },
        }}},[_c('span',{staticClass:"text-decoration-underline vd-black"},[_vm._v(" "+_vm._s(_vm.video.project_account_manager)+" ")])])],1):_vm._e(),(_vm.video.project_producer)?_c('div',[_c('span',[_vm._v("Project Manager: ")]),_c('router-link',{attrs:{"to":{
          name: 'videos.library',
          query: { 'project_producer[0]': _vm.video.project_producer },
        }}},[_c('span',{staticClass:"text-decoration-underline vd-black"},[_vm._v(" "+_vm._s(_vm.video.project_producer)+" ")])])],1):_vm._e()]):_vm._e(),(_vm.canViewMore)?_c('div',{staticClass:"vd-margin-top-small"},[_c('span',{staticClass:"vd-a",on:{"click":_vm.toggleVideoPreviewDescriptionShowModal}},[_vm._v("View more")])]):_vm._e(),_c('div',{staticClass:"vd-margin-top-large"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{class:[
          _vm.isMobileScreen
            ? 'row vd-padding-bottom-small vd-full-width vd-padding-left-medium'
            : 'vd-padding-top-medium' ]},[_c('router-link',{class:[
            'vms-video__preview--btn-view-project vd-display-inline-block vd-btn-small vd-btn-blue vd-margin-right-medium',
            _vm.isMobileScreen
              ? 'vms-video__preview--btn-view-project__mobile vd-margin-bottom-small'
              : '' ],attrs:{"to":{
            name: 'project.details.summary',
            params: { projectId: _vm.video.project_id },
          }},nativeOn:{"click":function($event){return _vm.viewProjectSendEvent()}}},[_c('span',{staticClass:"vd-white"},[_vm._v(" View Project ")])]),(!_vm.isMobileDevice)?_c('div',{class:[
            'vms-video__preview--btn-copy-link vd-display-inline-block vd-margin-right-medium',
            _vm.isMobileScreen
              ? 'vms-video__preview--btn-copy-link__mobile vd-margin-bottom-small'
              : '' ],on:{"click":function($event){return _vm.copyUrlSendEvent()}}},[_c('clipboard-copy',{attrs:{"show-as-button":true,"copy-content":_vm.copyContent,"copy-as-html":true,"show-text":false,"show-toast-alert":true,"show-icon":false,"success-message":_vm.clipboardCopySuccessMessage,"container-class":"vd-full-width","button-class":"vd-btn-small vd-display-block vd-btn-white vd-full-width","copy-text":"Copy Hyperlink"}})],1):_vm._e()],1),_c('div',{class:[
          'vms-video__preview--options-wrapper vd-padding-top-medium',
          _vm.isMobileScreen ? 'vms-video__preview--options-wrapper__mobile' : '' ]},[_c('div',{staticClass:"vd-display-inline-block vd-margin-right-medium",on:{"click":_vm.handleEditVideoClick}},[_c('text-popover',{attrs:{"tooltip-text":"Edit video info","tooltip-icon":"vd-icon-pencil-grey-circle","tooltip-id":("editVideo-" + (_vm.video.video_id)),"tooltip-placement":"top"}})],1),_c('hero-star',{staticClass:"vd-margin-right-medium",attrs:{"video":_vm.videoData,"hero-star-icon":"vd-icon-star-circle"},on:{"star-updated":_vm.toggleVideoStar}}),_c('favourite-video',{staticClass:"vd-margin-right-medium vd-display-inline-block",attrs:{"video":_vm.videoData,"fav-video-icon":"vd-icon-heart","fav-video-icon-active":'vd-icon-heart vd-icon-heart__active'}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeVideoDropdownOptions),expression:"closeVideoDropdownOptions"}],staticClass:"vd-icon-three-dots-circle vd-margin-right-medium vd-position-relative vms-video__results--actions-options",on:{"click":_vm.toggleVideoDropdownOptions}},[(_vm.showVideoDropdownOptions)?_c('video-dropdown-options',{staticClass:"vd-margin-top-medium",attrs:{"video-details":_vm.video},on:{"handle:vlp-click":function($event){return _vm.vlpClickSendEvent()},"handle:copy-private":function($event){return _vm.copyPrivateUrlSendEvent()}}}):_vm._e()],1)],1)])]),(_vm.showPreviewDescriptionModal)?_c('video-more-details-modal',{attrs:{"video":_vm.video}}):_vm._e(),(_vm.canShowVideoInfoModal)?_c('video-info-modal',{attrs:{"video-id":_vm.video.video_id}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }