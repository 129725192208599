<template>
  <div @click.prevent="handleUpdateFavouriteVideo">
    <text-popover
      :tooltip-text="toolTipText"
      :tooltip-icon="toolTipIcon"
      :custom-tooltip-class="customClass"
      :tooltip-id="`markAsFav-${videoId}`"
      tooltip-placement="top"
    ></text-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { includes } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'
import algoliaAnalytics from 'search-insights'

if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

export default {
  components: {
    TextPopover,
  },

  props: {
    video: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    favVideoIcon: {
      type: String,
      required: false,
      default: 'vd-icon-heart-transparent-grey-border',
    },

    favVideoIconActive: {
      type: String,
      required: false,
      default: 'vd-icon-heart-red',
    },

    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      videoId: this.video.video_id,
      isFavourite: 0,
    }
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),

    toolTipIcon() {
      return this.isFavourite ? this.favVideoIconActive : this.favVideoIcon
    },

    toolTipText() {
      return this.isFavourite ? 'Remove from favourites' : 'Mark as favourite'
    },

    videoQueryId() {
      return this.video.__queryID
        ? this.video.__queryID
        : this.$route.query.queryId
    },
  },

  watch: {
    'loggedInUser.id': {
      handler() {
        this.isFavourite = includes(
          this.video.favourite_users,
          this.loggedInUser.id
        )

        if (this.loggedInUser.id) {
          algoliaAnalytics('setUserToken', this.loggedInUser.id.toString())
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      updateFavouriteVideo: 'videos/updateFavouriteVideo',
    }),

    handleUpdateFavouriteVideo() {
      const payload = {
        videoId: this.videoId,
        is_favourite: this.isFavourite ? 0 : 1,
      }

      if (this.video.objectID) {
        algoliaAnalytics('convertedObjectIDs', {
          eventName: 'Item favourite clicked',
          index: 'videos_index',
          objectIDs: [this.video.objectID],
          userToken: this.loggedInUser.id.toString(),
          queryID: this.videoQueryId,
        })
      }

      this.updateFavouriteVideo(payload).then(() => {
        this.isFavourite = !this.isFavourite
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/favourite-video';
</style>
