<template>
  <videos-layout>
    <div
      v-if="video"
      slot="content-header"
      :class="[
        'vd-background-lightest-grey',
        isMobileScreen
          ? ''
          : 'vd-margin-bottom-extra-large vms-content__container',
      ]"
    >
      <video-breadcrumbs
        :video="video"
        class="vd-padding-left-medium"
      ></video-breadcrumbs>

      <div
        :class="[
          isMobileScreen
            ? 'vd-padding-top-medium'
            : 'vms-video__preview vd-padding-extra-large vd-padding-bottom-70',
        ]"
      >
        <div
          :class="[isMobileScreen ? '' : 'd-xl-flex justify-content-xl-center']"
        >
          <div :class="[isMobileScreen ? '' : 'col-12 col-xl-6']">
            <div
              :class="[
                'vms-video__preview--video',
                isMobileScreen ? '' : 'vd-padding-right-medium',
              ]"
            >
              <video-player
                :video-options="videoOptions"
                :video-object-id="video.objectID"
                :video-query-id="videoQueryId"
                :track-algolia-analytics="true"
              ></video-player>
            </div>
          </div>
          <video-preview-description
            :video="video"
            class="col-auto"
          ></video-preview-description>
        </div>
      </div>
    </div>

    <related-videos
      v-if="video"
      slot="content-body"
      :video="video"
    ></related-videos>

    <div v-if="!video" slot="content-body">
      <default-loader class="vms-loader__visdom-shield--full"></default-loader>
    </div>
  </videos-layout>
</template>

<script>
import VideosLayout from '@/layouts/videos-layout'
import RelatedVideos from '@views/videos/related-videos/related-videos'
import VideoPlayer from '@components/video-player/video-player'
import VideoPreviewDescription from '@views/videos/video-preview/video-preview-description'
import VideoBreadcrumbs from '@views/videos/components/video-breadcrumbs'
import algoliaSearch from '@plugins/algolia'
import DefaultLoader from '@components/loaders/default-loader'
import { mapGetters } from 'vuex'

export default {
  components: {
    VideosLayout,
    RelatedVideos,
    VideoPlayer,
    VideoPreviewDescription,
    VideoBreadcrumbs,
    DefaultLoader,
  },

  metaInfo() {
    return {
      title: this.video
        ? `${this.video.video_title} | Video Library`
        : `${this.$route.meta.title}`,
    }
  },

  data() {
    return {
      video: null,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    videoOptions() {
      return {
        controls: true,
        poster: this.video.thumbnail_url,
        sources: [
          {
            src: this.video.mp4_url,
            type: 'video/mp4',
          },
        ],
        aspectRatio: '16:9',
        preload: true,
        autoplay: false,
        userActions: {
          hotkeys: false,
        },
      }
    },

    videoQueryId() {
      return this.$route.query.queryId
    },
  },

  watch: {
    $route() {
      // Reset video to re-mount video player.
      this.video = null
      this.initAlgolia()
    },
  },

  mounted() {
    this.initAlgolia()
  },

  methods: {
    initAlgolia() {
      const videoIndex = algoliaSearch.initIndex('videos_index')
      videoIndex
        .search('', { filters: `video_id=${this.$route.params.videoId}` })
        .then(({ hits }) => {
          this.video = hits[0]
        })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/videos/video-library';
</style>
