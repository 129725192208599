var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-margin-top-small"},[(_vm.videoDetails.project_id)?_c('router-link',{class:[
      'vd-display-inline-block',
      _vm.isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium' ],attrs:{"to":{
      name: 'project.details.summary',
      params: { projectId: _vm.videoDetails.project_id },
    }},nativeOn:{"click":function($event){return _vm.handleProjectClick()}}},[_c('text-popover',{attrs:{"tooltip-text":"View project","tooltip-icon":"vms-video__results--content__description--icon vd-icon-eye-transparent-grey-border","tooltip-id":("viewProjectDiv-" + (_vm.videoDetails.video_id)),"tooltip-placement":"top"}})],1):_vm._e(),(!_vm.isMobileDevice)?_c('div',{class:[
      'vd-display-inline-block',
      _vm.isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium' ],on:{"click":function($event){$event.preventDefault();return _vm.handleCopyUrl()}}},[_c('clipboard-copy',{attrs:{"custom-icon":"vms-video__results--content__description--icon vd-icon-copy-transparent-grey-border","copy-content":_vm.copyContent,"show-text":false,"show-toast-alert":true,"copy-text":'Copy Hyperlink',"success-message":_vm.clipboardCopySuccessMessage,"show-label-as-tooltip":true,"copy-as-html":true,"tooltip-id":("copyVideoLinkDiv-" + (_vm.videoDetails.video_id))}})],1):_vm._e(),_c('div',{class:[
      'vd-display-inline-block',
      _vm.isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium' ],on:{"click":function($event){$event.preventDefault();return _vm.handleToggleVideoInformationModal()}}},[_c('text-popover',{attrs:{"tooltip-text":"Edit video info","tooltip-icon":"vms-video__results--content__description--icon vd-icon-pencil-transparent-grey-border","tooltip-id":("editVideoDiv-" + (_vm.videoDetails.video_id)),"tooltip-placement":"top"}})],1),_c('favourite-video',{class:[
      'vd-display-inline-block',
      _vm.isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium' ],attrs:{"custom-class":'vms-video__results--content__description--icon',"video":_vm.videoData}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeVideoDropdownOptions),expression:"closeVideoDropdownOptions"}],staticClass:"vd-margin-right-medium vd-display-inline-block vms-video__results--actions-options vms-video__results--content__description--icon vd-icon-three-dots-transparent-grey-border vd-position-relative",on:{"click":function($event){$event.stopPropagation();return _vm.toggleVideoDropdownOptions($event)}}},[(_vm.showVideoDropdownOptions)?_c('video-dropdown-options',{staticClass:"vd-margin-top-medium",attrs:{"video-details":_vm.videoDetails},on:{"handle:vlp-click":function($event){return _vm.handleVlpClick()},"handle:copy-private":function($event){return _vm.handleCopyPrivate()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }