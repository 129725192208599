<template>
  <div class="vms-video__no-results">
    <div class="vms-video__no-results--wrapper vd-text-align-center">
      <slot name="empty-content">
        <div class="vd-margin-bottom-10">Uh oh, nothing came up.</div>
        <div class="vd-margin-bottom-10">
          Have you tried looking under private videos?
        </div>
        <div class="vd-margin-bottom-10">
          Perhaps a different search term might help.
        </div>
      </slot>
      <img class="vms-video__no-results--img" :src="imageSrc" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: false,
      default: '/images/empty-states/empty-library.svg',
    },
  },
}
</script>
