<template>
  <v-app>
    <main-layout>
      <div slot="content-body" class="vms-video">
        <div :class="defaultHeaderPaddingClass">
          <slot name="content-header"></slot>
        </div>
        <slot name="content-body"></slot>
      </div>
    </main-layout>
  </v-app>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
import Vuetify from 'vuetify'

export default {
  components: {
    MainLayout,
  },

  vuetify: new Vuetify(),

  props: {
    defaultHeaderPaddingClass: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/layouts/videos-layout';
</style>
