<template>
  <div
    v-if="canDisplayFilter"
    class="d-flex align-items-center vd-margin-bottom-extra-small"
  >
    <input
      :id="identifier"
      :type="isRadioButton ? 'radio' : 'checkbox'"
      class="vd-margin-right-small"
      :checked="checked"
      :value="value"
      @click="handleClick()"
    />

    <label :for="identifier" class="vd-margin-0 d-flex">
      <span v-if="truncateLabel" class="vd-margin-right-small" :title="label">{{
        label | truncateString(22)
      }}</span>
      <span v-else class="vd-margin-right-small">{{ label }}</span>
      <span
        v-if="iconClass"
        :class="[
          'vms-video__filters-checkbox--icon vd-margin-right-extra-small',
          iconClass,
        ]"
      />
      <span v-if="hasCount">({{ count }})</span>
    </label>
  </div>
</template>

<script>
import { kebabCase } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],
  props: {
    idPrefix: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: true,
      default: '',
    },

    value: {
      type: [String, Number, Object, null],
      required: false,
      default: '',
    },

    count: {
      type: Number,
      require: false,
      default: -1,
    },

    checked: {
      type: Boolean,
      required: false,
      default: false,
    },

    isRadioButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    truncateLabel: {
      type: Boolean,
      required: false,
      default: true,
    },

    iconClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    identifier() {
      let formattedLabel = kebabCase(this.label)

      if (this.idPrefix) {
        formattedLabel = `${this.idPrefix}-${formattedLabel}`
      }

      return formattedLabel
    },

    hasCount() {
      return this.count > 0
    },

    canDisplayFilter() {
      return this.count === -1 || this.hasCount
    },
  },

  methods: {
    handleClick() {
      this.$emit('handle:checkbox-click', this.checked)
    },
  },
}
</script>
