var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-modal',{class:['show', _vm.isMobileScreen ? 'vms-modal--fullscreen' : ''],attrs:{"modal-dialog-class":"modal-lg","content-class":"vd-background-white"}},[_c('div',{staticClass:"modal-header",attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('div',{staticClass:"vms-video__preview--title"},[_c('span',{class:[
          'vms-video__preview--icons-privacy vd-margin-right-small',
          _vm.video.public_video
            ? 'vd-icon-video-public'
            : 'vd-icon-video-private' ]}),_c('span',{staticClass:"vms-video__preview--title-text vd-h2"},[_vm._v(_vm._s(_vm._f("truncateString")(_vm.video.video_title,65)))])]),_c('div',{staticClass:"vms-modal__header--close close vd-black"},[_c('span',{attrs:{"id":"closeVideoMoreDetailsSpan"},on:{"click":_vm.toggleVideoPreviewDescriptionShowModal}},[_vm._v("x")])])]),_c('div',{staticClass:"vms-video__preview--description-modal vd-padding-top-small vd-padding-right-large vd-padding-left-large vd-padding-bottom-large",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('div',{staticClass:"vd-h5 vd-margin-bottom-small"},[_c('span',[_vm._v("Date created: "+_vm._s(_vm._f("unixToFormat")(_vm.video.uploaded_timestamp,'D/M/YYYY')))])]),_c('div',{staticClass:"vd-margin-bottom-small"},[_c('p',[_vm._v(_vm._s(_vm.video.description))])]),_c('div',{staticClass:"vd-margin-bottom-small"},[(_vm.video.project_price)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Project price:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              project_price: ((_vm.video.project_price) + ":" + (_vm.video.project_price)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.video.project_price)))])])],1):_vm._e(),(_vm.video.project_production_hours)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Total production hours:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              project_production_hours: ((_vm.video.project_production_hours) + ":" + (_vm.video.project_production_hours)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_production_hours))])])],1):_vm._e(),(_vm.video.product_type)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Product type:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'product_type[0]': _vm.video.product_type },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.product_type))])])],1):_vm._e(),(_vm.hasVideoStyle)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Video style:")])]),_vm._l((_vm.video.style_types),function(style,index){return _c('router-link',{key:index,attrs:{"to":{
            name: 'videos.library',
            query: { 'style_types[0]': style },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[(index > 0)?_c('span',{staticClass:"vd-margin-right-extra-small"},[_vm._v(",")]):_vm._e(),_vm._v(_vm._s(style)+" ")])])})],2):_vm._e(),(_vm.video.industry_type)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Video industry:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'industry_type[0]': _vm.video.industry_type },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.industry_type))])])],1):_vm._e(),(_vm.video.office_name)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Office:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'office_name[0]': _vm.video.office_name },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black vd-black"},[_vm._v(_vm._s(_vm.video.office_name))])])],1):_vm._e(),(_vm.video.project_producer)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Project manager:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'project_producer[0]': _vm.video.project_producer },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_producer))])])],1):_vm._e(),(_vm.video.project_director)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Director:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'project_director[0]': _vm.video.project_director },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_director))])])],1):_vm._e(),(_vm.video.project_account_manager)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Relationship manager:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              'project_account_manager[0]': _vm.video.project_account_manager,
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_account_manager))])])],1):_vm._e(),(_vm.video.project_production_coordinator)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Production coordinator:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              'project_production_coordinator[0]':
                _vm.video.project_production_coordinator,
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_production_coordinator))])])],1):_vm._e(),(_vm.hasCrew)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Crew:")])]),_vm._l((_vm.video.project_crew),function(crew,index){return _c('router-link',{key:index,attrs:{"to":{
            name: 'videos.library',
            query: { 'project_crew[0]': crew },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[(index > 0)?_c('span',{staticClass:"vd-margin-right-extra-small"},[_vm._v(",")]):_vm._e(),_vm._v(_vm._s(crew)+" ")])])})],2):_vm._e(),(_vm.video.project_shoot_crew_size)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Filming crew size:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              'project_shoot_crew_size[0]': _vm.video.project_shoot_crew_size,
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_shoot_crew_size))])])],1):_vm._e(),(_vm.video.project_platform)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Primary platform:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            name: 'videos.library',
            query: { 'project_platform[0]': _vm.video.project_platform },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_platform))])])],1):_vm._e(),(_vm.video.project_target_audience)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Target audience:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              'project_target_audience_types[0]':
                _vm.video.project_target_audience_types,
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_target_audience_types))])])],1):_vm._e(),(_vm.video.duration)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Duration:")])]),_vm._v(" "+_vm._s(_vm.videoDuration)+" ")]):_vm._e(),(_vm.video.aspect_ratio)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Aspect ratio:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'aspect_ratio[0]': _vm.video.aspect_ratio },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.aspect_ratio))])])],1):_vm._e(),(_vm.video.resolution)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Resolution:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: { 'resolution[0]': _vm.video.resolution },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.resolution))])])],1):_vm._e(),(_vm.video.project_preproduction_hours)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Pre-production hours:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              project_preproduction_hours: ((_vm.video.project_preproduction_hours) + ":" + (_vm.video.project_preproduction_hours)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_preproduction_hours))])])],1):_vm._e(),(_vm.video.project_filming_hours)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Filming hours:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              project_filming_hours: ((_vm.video.project_filming_hours) + ":" + (_vm.video.project_filming_hours)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_filming_hours))])])],1):_vm._e(),(_vm.video.project_editing_hours)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Editing hours:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              project_editing_hours: ((_vm.video.project_editing_hours) + ":" + (_vm.video.project_editing_hours)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm.video.project_editing_hours))])])],1):_vm._e(),(_vm.hasDisbursements)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Disbursements:")])]),_vm._l((_vm.video.project_disbursement),function(disbursement,index){return _c('router-link',{key:index,attrs:{"to":{
            name: 'videos.library',
            query: { 'project_disbursement[0]': disbursement },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black vd-margin-right-extra-small"},[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(disbursement))])])})],2):_vm._e(),(_vm.video.company_target)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("House accounts target:")])]),_c('router-link',{attrs:{"to":{
            name: 'videos.library',
            query: {
              company_target: ((_vm.video.company_target) + ":" + (_vm.video.company_target)),
            },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.video.company_target)))])])],1):_vm._e(),(_vm.hasYearlyObjective)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Company objectives:")])]),_vm._l((_vm.video.office_yearly_objective_types),function(objective,index){return _c('router-link',{key:index,attrs:{"to":{
            name: 'videos.library',
            query: { 'office_yearly_objective_types[0]': objective },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[(index > 0)?_c('span',{staticClass:"vd-margin-right-extra-small"},[_vm._v(",")]):_vm._e(),_vm._v(_vm._s(objective)+" ")])])})],2):_vm._e(),(_vm.hasTags)?_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_c('span',{staticClass:"vd-margin-right-extra-small"},[_c('strong',[_vm._v("Video tags:")])]),_vm._l((_vm.video.tags),function(tag,index){return _c('router-link',{key:index,attrs:{"to":{
            name: 'videos.library',
            query: { 'tags[0]': tag },
          }}},[_c('span',{staticClass:"vms-video__preview--description-info vd-black"},[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(tag)+" ")])])})],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }