<template>
  <div>
    <div class="vms-video__preview--title">
      <span
        :class="[
          'vms-video__preview--icons-privacy vd-margin-right-small',
          video.public_video ? 'vd-icon-video-public' : 'vd-icon-video-private',
          isMobileScreen ? 'vms-video__preview--icons-privacy__mobile' : '',
        ]"
      ></span>
      <span
        :class="[
          'vms-video__preview--title-text',
          isMobileScreen ? 'vd-h3' : 'vd-h2',
        ]"
      >
        {{ video.video_title | truncateString(65) }}
      </span>
    </div>

    <div class="vd-h5 vd-margin-top-small">
      <span>
        Date created:
        {{ video.uploaded_timestamp | unixToFormat('D/M/YYYY') }}
      </span>
    </div>

    <div class="vd-margin-top-small">
      <p>{{ video.description | truncateString(250) }}</p>
    </div>

    <div v-if="canDisplaySubInfo" class="vd-margin-top-small">
      <div v-if="video.office_name">
        <span>Office: </span>
        <router-link
          :to="{
            name: 'videos.library',
            query: { 'office_name[0]': video.office_name },
          }"
        >
          <span class="text-decoration-underline vd-black">
            {{ video.office_name }}
          </span>
        </router-link>
      </div>

      <div v-if="video.product_type">
        <span>Product Type: </span>
        <router-link
          :to="{
            name: 'videos.library',
            query: { 'product_type[0]': video.product_type },
          }"
        >
          <span class="text-decoration-underline vd-black">
            {{ video.product_type }}
          </span>
        </router-link>
      </div>

      <div v-if="video.project_account_manager">
        <span>Relationship Manager: </span>
        <router-link
          :to="{
            name: 'videos.library',
            query: {
              'project_account_manager[0]': video.project_account_manager,
            },
          }"
        >
          <span class="text-decoration-underline vd-black">
            {{ video.project_account_manager }}
          </span>
        </router-link>
      </div>

      <div v-if="video.project_producer">
        <span>Project Manager: </span>
        <router-link
          :to="{
            name: 'videos.library',
            query: { 'project_producer[0]': video.project_producer },
          }"
        >
          <span class="text-decoration-underline vd-black">
            {{ video.project_producer }}
          </span>
        </router-link>
      </div>
    </div>

    <div v-if="canViewMore" class="vd-margin-top-small">
      <span class="vd-a" @click="toggleVideoPreviewDescriptionShowModal"
        >View more</span
      >
    </div>
    <div class="vd-margin-top-large">
      <div class="row no-gutters">
        <div
          :class="[
            isMobileScreen
              ? 'row vd-padding-bottom-small vd-full-width vd-padding-left-medium'
              : 'vd-padding-top-medium',
          ]"
        >
          <router-link
            :to="{
              name: 'project.details.summary',
              params: { projectId: video.project_id },
            }"
            :class="[
              'vms-video__preview--btn-view-project vd-display-inline-block vd-btn-small vd-btn-blue vd-margin-right-medium',
              isMobileScreen
                ? 'vms-video__preview--btn-view-project__mobile vd-margin-bottom-small'
                : '',
            ]"
            @click.native="viewProjectSendEvent()"
          >
            <span class="vd-white"> View Project </span>
          </router-link>

          <div
            v-if="!isMobileDevice"
            :class="[
              'vms-video__preview--btn-copy-link vd-display-inline-block vd-margin-right-medium',
              isMobileScreen
                ? 'vms-video__preview--btn-copy-link__mobile vd-margin-bottom-small'
                : '',
            ]"
            @click="copyUrlSendEvent()"
          >
            <clipboard-copy
              :show-as-button="true"
              :copy-content="copyContent"
              :copy-as-html="true"
              :show-text="false"
              :show-toast-alert="true"
              :show-icon="false"
              :success-message="clipboardCopySuccessMessage"
              container-class="vd-full-width"
              button-class="vd-btn-small vd-display-block vd-btn-white vd-full-width"
              copy-text="Copy Hyperlink"
            ></clipboard-copy>
          </div>
        </div>

        <div
          :class="[
            'vms-video__preview--options-wrapper vd-padding-top-medium',
            isMobileScreen ? 'vms-video__preview--options-wrapper__mobile' : '',
          ]"
        >
          <div
            class="vd-display-inline-block vd-margin-right-medium"
            @click="handleEditVideoClick"
          >
            <text-popover
              tooltip-text="Edit video info"
              :tooltip-icon="`vd-icon-pencil-grey-circle`"
              :tooltip-id="`editVideo-${video.video_id}`"
              tooltip-placement="top"
            ></text-popover>
          </div>

          <hero-star
            class="vd-margin-right-medium"
            :video="videoData"
            :hero-star-icon="`vd-icon-star-circle`"
            @star-updated="toggleVideoStar"
          ></hero-star>

          <favourite-video
            class="vd-margin-right-medium vd-display-inline-block"
            :video="videoData"
            :fav-video-icon="`vd-icon-heart`"
            :fav-video-icon-active="'vd-icon-heart vd-icon-heart__active'"
          ></favourite-video>

          <div
            v-click-outside="closeVideoDropdownOptions"
            class="vd-icon-three-dots-circle vd-margin-right-medium vd-position-relative vms-video__results--actions-options"
            @click="toggleVideoDropdownOptions"
          >
            <video-dropdown-options
              v-if="showVideoDropdownOptions"
              class="vd-margin-top-medium"
              :video-details="video"
              @handle:vlp-click="vlpClickSendEvent()"
              @handle:copy-private="copyPrivateUrlSendEvent()"
            ></video-dropdown-options>
          </div>
        </div>
      </div>
    </div>

    <video-more-details-modal
      v-if="showPreviewDescriptionModal"
      :video="video"
    ></video-more-details-modal>

    <video-info-modal
      v-if="canShowVideoInfoModal"
      :video-id="video.video_id"
    ></video-info-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { size } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import VideoMoreDetailsModal from '@views/videos/video-preview/video-preview-more-details'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import VideoDropdownOptions from '@views/videos/components/video-dropdown-options'
import VideoInfoModal from '@views/videos/components/video-information-modal'
import HeroStar from '@components/hero-star/hero-star'
import FavouriteVideo from '@components/favourite-video/favourite-video'
import algoliaAnalytics from 'search-insights'
import TextPopover from '@components/tooltips/text-popover'
import SweetAlert from '@plugins/sweet-alert'
import ClickOutside from 'vue-click-outside'

// Don't initialize analytics on test env.
if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

export default {
  components: {
    VideoMoreDetailsModal,
    ClipboardCopy,
    VideoDropdownOptions,
    VideoInfoModal,
    HeroStar,
    FavouriteVideo,
    TextPopover,
  },

  directives: {
    ClickOutside,
  },

  mixins: [FiltersMixin],

  props: {
    video: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      videoData: this.video,
      showVideoDropdownOptions: false,
    }
  },

  computed: {
    ...mapGetters({
      windowWidth: 'common/windowWidth',
      showPreviewDescriptionModal: 'videos/showPreviewDescriptionModal',
      showVideoInfoModal: 'videos/showVideoInfoModal',
      selectedModalVideoId: 'videos/selectedModalVideoId',
      loggedInUser: 'auth/getVmsUserData',
      isMobileScreen: 'common/isMobileScreen',
      isMobileDevice: 'common/isMobileDevice',
    }),

    copyContent() {
      return `
        <a ref="link" style="text-decoration: underline" href="${this.video.video_url}">
          ${this.video.video_title}
        </a>`
    },

    canDisplaySubInfo() {
      return this.windowWidth > 1650
    },

    clipboardCopySuccessMessage() {
      return `Successfully copied ${this.video.video_title} video url!`
    },

    canShowVideoInfoModal() {
      return (
        this.video.video_id === this.selectedModalVideoId &&
        this.showVideoInfoModal
      )
    },

    // eslint-disable-next-line complexity
    canViewMore() {
      return (
        this.video.project_price ||
        this.video.project_production_hours ||
        this.video.product_type ||
        size(this.video.style_types) > 0 ||
        this.video.industry_type ||
        this.video.office_name ||
        this.video.project_producer ||
        this.video.project_director ||
        this.video.project_account_manager ||
        this.video.project_production_coordinator ||
        size(this.video.project_crew) > 0 ||
        this.video.project_shoot_crew_size ||
        this.video.project_platform ||
        this.video.project_target_audience ||
        this.video.duration ||
        this.video.aspect_ratio ||
        this.video.resolution ||
        this.video.project_preproduction_hours ||
        this.video.project_filming_hours ||
        this.video.project_editing_hours ||
        size(this.video.project_disbursement) > 0 ||
        this.video.company_target ||
        size(this.video.office_yearly_objective_types) > 0 ||
        size(this.video.tags) > 0
      )
    },

    videoQueryId() {
      return this.$route.query.queryId
    },
  },

  watch: {
    'loggedInUser.id': (val) => {
      if (val) {
        algoliaAnalytics('setUserToken', val.toString())
      }
    },
  },

  mounted() {
    if (this.showPreviewDescriptionModal) {
      this.toggleVideoPreviewDescriptionShowModal()
    }

    if (this.showVideoInfoModal) {
      this.toggleVideoInformationShowModal()
    }

    this.pageViewedSendEvent()
  },

  methods: {
    ...mapActions({
      toggleVideoPreviewDescriptionShowModal:
        'videos/toggleVideoPreviewDescriptionShowModal',
      toggleVideoInformationShowModal: 'videos/toggleVideoInformationShowModal',
    }),
    handleEditVideoClick() {
      this.toggleVideoInformationShowModal(this.video.video_id)
    },

    pageViewedSendEvent() {
      if (this.loggedInUser.id) {
        algoliaAnalytics('convertedObjectIDs', {
          eventName: 'Item Selected',
          index: 'videos_index',
          objectIDs: [this.video.objectID],
          userToken: this.loggedInUser.id.toString(),
          queryID: this.videoQueryId,
        })
      }
    },

    viewProjectSendEvent() {
      algoliaAnalytics('convertedObjectIDs', {
        eventName: 'Item Project Clicked',
        index: 'videos_index',
        objectIDs: [this.video.objectID],
        queryID: this.videoQueryId,
      })
    },

    copyUrlSendEvent() {
      algoliaAnalytics('convertedObjectIDs', {
        eventName: 'Item Recommended',
        index: 'videos_index',
        objectIDs: [this.video.objectID],
        queryID: this.videoQueryId,
      })

      if (!this.video.public_video) {
        this.showPopupWarning()
      }
    },

    vlpClickSendEvent() {
      algoliaAnalytics('convertedObjectIDs', {
        eventName: 'Item VLP Clicked',
        index: 'videos_index',
        objectIDs: [this.video.objectID],
        queryID: this.videoQueryId,
      })
    },

    copyPrivateUrlSendEvent() {
      algoliaAnalytics('convertedObjectIDs', {
        eventName: 'Item Shared',
        index: 'videos_index',
        objectIDs: [this.video.objectID],
        queryID: this.videoQueryId,
      })

      if (!this.video.public_video) {
        this.showPopupWarning()
      }
    },

    toggleVideoStar() {
      this.videoData.is_starred = !this.videoData.is_starred
    },

    showPopupWarning() {
      SweetAlert.fire({
        title: 'Warning',
        html: `<br>This is a private video. Be careful when sharing as it has been indicated for private use only.`,
        showCancelButton: false,
        confirmButtonText: 'Copy Link',
      })
    },

    toggleVideoDropdownOptions() {
      this.showVideoDropdownOptions = !this.showVideoDropdownOptions
    },

    closeVideoDropdownOptions() {
      this.showVideoDropdownOptions = false
    },
  },
}
</script>
