<template>
  <div class="vd-margin-top-small">
    <router-link
      v-if="videoDetails.project_id"
      :to="{
        name: 'project.details.summary',
        params: { projectId: videoDetails.project_id },
      }"
      :class="[
        'vd-display-inline-block',
        isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium',
      ]"
      @click.native="handleProjectClick()"
    >
      <text-popover
        tooltip-text="View project"
        tooltip-icon="vms-video__results--content__description--icon vd-icon-eye-transparent-grey-border"
        :tooltip-id="`viewProjectDiv-${videoDetails.video_id}`"
        tooltip-placement="top"
      ></text-popover>
    </router-link>

    <div
      v-if="!isMobileDevice"
      :class="[
        'vd-display-inline-block',
        isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium',
      ]"
      @click.prevent="handleCopyUrl()"
    >
      <clipboard-copy
        custom-icon="vms-video__results--content__description--icon vd-icon-copy-transparent-grey-border"
        :copy-content="copyContent"
        :show-text="false"
        :show-toast-alert="true"
        :copy-text="'Copy Hyperlink'"
        :success-message="clipboardCopySuccessMessage"
        :show-label-as-tooltip="true"
        :copy-as-html="true"
        :tooltip-id="`copyVideoLinkDiv-${videoDetails.video_id}`"
      ></clipboard-copy>
    </div>

    <div
      :class="[
        'vd-display-inline-block',
        isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium',
      ]"
      @click.prevent="handleToggleVideoInformationModal()"
    >
      <text-popover
        tooltip-text="Edit video info"
        tooltip-icon="vms-video__results--content__description--icon vd-icon-pencil-transparent-grey-border"
        :tooltip-id="`editVideoDiv-${videoDetails.video_id}`"
        tooltip-placement="top"
      ></text-popover>
    </div>

    <favourite-video
      :class="[
        'vd-display-inline-block',
        isMobileScreen ? 'vd-margin-right-large' : 'vd-margin-right-medium',
      ]"
      :custom-class="'vms-video__results--content__description--icon'"
      :video="videoData"
    ></favourite-video>

    <div
      v-click-outside="closeVideoDropdownOptions"
      class="vd-margin-right-medium vd-display-inline-block vms-video__results--actions-options vms-video__results--content__description--icon vd-icon-three-dots-transparent-grey-border vd-position-relative"
      @click.stop="toggleVideoDropdownOptions"
    >
      <video-dropdown-options
        v-if="showVideoDropdownOptions"
        :video-details="videoDetails"
        class="vd-margin-top-medium"
        @handle:vlp-click="handleVlpClick()"
        @handle:copy-private="handleCopyPrivate()"
      ></video-dropdown-options>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import VideoDropdownOptions from '@views/videos/components/video-dropdown-options'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import FavouriteVideo from '@components/favourite-video/favourite-video'
import SweetAlert from '@plugins/sweet-alert'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    TextPopover,
    VideoDropdownOptions,
    ClipboardCopy,
    FavouriteVideo,
  },

  directives: {
    ClickOutside,
  },

  props: {
    videoDetails: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      videoData: this.videoDetails,
      showVideoDropdownOptions: false,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isMobileDevice: 'common/isMobileDevice',
    }),

    clipboardCopySuccessMessage() {
      return `Successfully copied ${this.videoDetails.video_title} video url!`
    },

    copyContent() {
      return `<a style="text-decoration:underline;" href="${this.videoDetails.video_url}">${this.videoDetails.video_title}</a>`
    },
  },

  methods: {
    ...mapActions({
      toggleVideoInformationShowModal: 'videos/toggleVideoInformationShowModal',
    }),

    handleToggleVideoInformationModal() {
      this.toggleVideoInformationShowModal(this.videoDetails.video_id)
    },

    handleProjectClick() {
      this.$emit('handle:project-click')
    },

    handleCopyUrl() {
      this.$emit('handle:copy-url')

      if (!this.videoDetails.public_video) {
        this.showPopupWarning()
      }
    },

    handleVlpClick() {
      this.$emit('handle:vlp-click')
    },

    handleCopyPrivate() {
      this.$emit('handle:copy-private')

      if (!this.videoDetails.public_video) {
        this.showPopupWarning()
      }
    },

    toggleVideoStar() {
      this.videoData.is_starred = !this.videoData.is_starred
    },

    showPopupWarning() {
      SweetAlert.fire({
        title: 'Warning',
        html: `<br>This is a private video. Be careful when sharing as it has been indicated for private use only.`,
        showCancelButton: false,
        confirmButtonText: 'Copy Link',
      })
    },

    toggleVideoDropdownOptions() {
      this.showVideoDropdownOptions = !this.showVideoDropdownOptions
    },

    closeVideoDropdownOptions() {
      this.showVideoDropdownOptions = false
    },
  },
}
</script>
