<template>
  <div class="vms-video__privacy vd-margin-top-small">
    <span class="vd-margin-right-small vms-video__privacy--label"
      >Include private videos:</span
    >
    <span
      :class="[
        'vms-video__privacy--icon vd-margin-right-small',
        showPrivateVideos ? 'vd-icon-switch-blue' : 'vd-icon-switch-grey',
      ]"
      @click="toggleVideoPrivacy"
    ></span>
    <span class="vms-video__privacy--count">({{ privateVideoCount }})</span>
  </div>
</template>

<script>
import { find } from 'lodash'

export default {
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      showPrivateVideos: true,
    }
  },

  computed: {
    privateVideoCount() {
      const privateItem = find(this.items, { value: '0' })

      return privateItem ? privateItem.count : 0
    },
  },

  methods: {
    toggleVideoPrivacy() {
      this.showPrivateVideos = !this.showPrivateVideos
      this.$emit('handle:toggle-privacy')
    },
  },
}
</script>
