var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-masonry-wall',{attrs:{"items":_vm.filteredVideos,"options":_vm.layoutOptions},on:{"append":_vm.loadMoreVideos},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item)?_c('div',{staticClass:"vms-video__results--content vd-position-relative"},[_c('video-thumbnail-media',{attrs:{"item":item}}),_c('div',{staticClass:"vd-position-absolute vms-video__results--tag"},[(item.is_starred)?_c('div',{staticClass:"vms-video__results--tag__hero vd-display-inline-block vd-border-radius-25 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10 vd-margin-right-extra-small"},[_c('span',{staticClass:"vd-display-inline-block vd-icon-star-yellow vms-video__results--private-icon"})]):_vm._e(),(!item.public_video)?_c('div',{staticClass:"vms-video__results--tag__private vd-display-inline-block vd-border-radius-25 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"},[_c('span',{staticClass:"vd-icon-padlock vms-video__results--private-icon vd-margin-right-extra-small"}),_c('span',{staticClass:"vd-white"},[_vm._v("Private")])]):_vm._e()]),_c('div',{staticClass:"vms-video__results--content__video-info"},[_c('div',[_c('router-link',{staticClass:"vd-display-inline-block",attrs:{"to":{
              name: 'video.details',
              params: { videoId: item.video_id },
              query: { queryId: item.__queryID },
            }}},[_c('div',{staticClass:"vd-h5 vd-light-blue vd-margin-top-small",on:{"click":function($event){return _vm.handleRouterClick(item)}}},[_vm._v(" "+_vm._s(_vm._f("truncateString")(item.office_name,45))+" ")]),_c('div',{staticClass:"vd-h5 vd-black vd-margin-top-extra-small",on:{"click":function($event){return _vm.handleRouterClick(item)}}},[_vm._v(" "+_vm._s(_vm._f("truncateString")(item.video_title,90))+" ")])])],1),_c('video-thumbnail-options',{key:item.video_id,class:[_vm.isMobileScreen ? 'vd-margin-top-medium float-end' : ''],attrs:{"video-details":item},on:{"handle:project-click":function($event){return _vm.handleProjectClick(item)},"handle:copy-url":function($event){return _vm.handleCopyUrl(item)},"handle:vlp-click":function($event){return _vm.handleVlpClick(item)},"handle:copy-private":function($event){return _vm.handleCopyPrivate(item)}}})],1)],1):_vm._e(),(
        _vm.showVideoInfoModal && item && item.video_id === _vm.selectedModalVideoId
      )?_c('video-info-modal',{attrs:{"video-id":_vm.selectedModalVideoId}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }