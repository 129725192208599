<template>
  <vue-masonry-wall
    :items="filteredVideos"
    :options="layoutOptions"
    @append="loadMoreVideos"
  >
    <template v-slot="{ item }">
      <div v-if="item" class="vms-video__results--content vd-position-relative">
        <video-thumbnail-media :item="item"></video-thumbnail-media>

        <div class="vd-position-absolute vms-video__results--tag">
          <div
            v-if="item.is_starred"
            class="vms-video__results--tag__hero vd-display-inline-block vd-border-radius-25 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10 vd-margin-right-extra-small"
          >
            <span
              class="vd-display-inline-block vd-icon-star-yellow vms-video__results--private-icon"
            ></span>
          </div>
          <div
            v-if="!item.public_video"
            class="vms-video__results--tag__private vd-display-inline-block vd-border-radius-25 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
          >
            <span
              class="vd-icon-padlock vms-video__results--private-icon vd-margin-right-extra-small"
            ></span>
            <span class="vd-white">Private</span>
          </div>
        </div>

        <div class="vms-video__results--content__video-info">
          <div>
            <router-link
              :to="{
                name: 'video.details',
                params: { videoId: item.video_id },
                query: { queryId: item.__queryID },
              }"
              class="vd-display-inline-block"
            >
              <div
                class="vd-h5 vd-light-blue vd-margin-top-small"
                @click="handleRouterClick(item)"
              >
                {{ item.office_name | truncateString(45) }}
              </div>
              <div
                class="vd-h5 vd-black vd-margin-top-extra-small"
                @click="handleRouterClick(item)"
              >
                {{ item.video_title | truncateString(90) }}
              </div>
            </router-link>
          </div>

          <video-thumbnail-options
            :key="item.video_id"
            :video-details="item"
            :class="[isMobileScreen ? 'vd-margin-top-medium float-end' : '']"
            @handle:project-click="handleProjectClick(item)"
            @handle:copy-url="handleCopyUrl(item)"
            @handle:vlp-click="handleVlpClick(item)"
            @handle:copy-private="handleCopyPrivate(item)"
          ></video-thumbnail-options>
        </div>
      </div>
      <video-info-modal
        v-if="
          showVideoInfoModal && item && item.video_id === selectedModalVideoId
        "
        :video-id="selectedModalVideoId"
      ></video-info-modal>
    </template>
  </vue-masonry-wall>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter } from 'lodash'
import VueMasonryWall from 'vue-masonry-wall'
import StyleMixin from '@mixins/styles-mixin'

import FiltersMixin from '@mixins/filters-mixin'
import VideoThumbnailOptions from '@views/videos/video-thumbnail/video-thumbnail-options'
import VideoInfoModal from '@views/videos/components/video-information-modal'
import VideoThumbnailMedia from '@views/videos/video-thumbnail/video-thumbnail-media'

export default {
  components: {
    VueMasonryWall,
    VideoThumbnailOptions,
    VideoInfoModal,
    VideoThumbnailMedia,
  },

  mixins: [StyleMixin, FiltersMixin],

  props: {
    videos: {
      type: Array,
      required: true,
    },

    layoutOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          width: 425,
          padding: {
            default: 10,
          },
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      showVideoInfoModal: 'videos/showVideoInfoModal',
      selectedModalVideoId: 'videos/selectedModalVideoId',
      isMobileScreen: 'common/isMobileScreen',
    }),

    // This is a temporary solution to hide videos that hasn't been delivered.
    // Once we have the time to change algolia data, we have to do it properly via algolia filter.
    // By doing isDelivered filter that returns true or false.
    filteredVideos() {
      return filter(this.videos, (video) => {
        return video.mp4_url
      })
    },
  },

  methods: {
    loadMoreVideos() {
      this.$emit('handle:append-videos')
    },

    handleProjectClick(item) {
      this.$emit('handle:project-click', item)
    },

    handleCopyUrl(item) {
      this.$emit('handle:copy-url', item)
    },

    handleVlpClick(item) {
      this.$emit('handle:vlp-click', item)
    },

    handleCopyPrivate(item) {
      this.$emit('handle:copy-private', item)
    },

    handleRouterClick(item) {
      this.$emit('handle:item-click', item)
    },
  },
}
</script>
