<template>
  <default-modal
    class="show"
    :modal-dialog-class="[
      'modal-lg vms-form-modal__dialog',
      isMobileScreen ? 'vms-form-modal__dialog--mobile vd-margin-0' : '',
    ]"
    content-class="vd-background-white"
  >
    <div
      slot="modal-header"
      class="modal-header vms-form-modal__header vd-background-white"
    >
      <div class="vd-h4 vd-darker-grey">Video Information</div>
      <div class="vms-modal__header--close close vd-black">
        <span
          id="closeModalSpan"
          @click.prevent="toggleVideoInformationShowModal"
          >x</span
        >
      </div>
    </div>

    <div slot="modal-body" class="vms-form-modal__body">
      <div v-if="isVideoInfoLoading" class="vms-form-modal__loader">
        <default-loader></default-loader>
      </div>

      <div v-else>
        <div
          :class="[
            'vd-padding-top-medium vd-padding-bottom-large',
            isMobileScreen
              ? 'vd-padding-left-large vd-padding-right-large '
              : 'vd-padding-left-extra-large vd-padding-right-extra-large',
          ]"
        >
          <div class="vd-padding-bottom-medium d-flex justify-content-center">
            <div class="vms-form-modal__video-player">
              <video-player :video-options="videoOptions"></video-player>
            </div>
          </div>

          <v-app>
            <div class="row">
              <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                <div class="vd-padding-bottom-20 vd-padding-top-20">
                  <div
                    :class="[
                      'vd-field',
                      selectedVideoIndustry ? 'vd-input-valid' : '',
                    ]"
                  >
                    <label for="deliveriesVideoIndustrySelect"
                      >Video Industry</label
                    >
                    <model-list-select
                      id="deliveriesVideoIndustrySelect"
                      v-model="selectedVideoIndustry"
                      name="deliveriesVideoIndustrySelect"
                      :list="videoIndustryOptions"
                      option-value="id"
                      option-text="label"
                      placeholder="Select an industry"
                    ></model-list-select>
                  </div>
                </div>
              </div>

              <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                <div class="vd-padding-bottom-small vd-padding-top-20">
                  <v-select
                    id="videoStyleSelect"
                    v-model="selectedVideoStyleTags"
                    label="Video Styles"
                    :class="[
                      'vd-field',
                      selectedVideoStyleTags.length ? 'vd-input-valid' : '',
                    ]"
                    :items="videoStyleOptionsFormatted"
                    placeholder="Select video styles"
                    persistent-placeholder
                    multiple
                    chips
                    small-chips
                    clearable
                    :deletable-chips="true"
                    :hide-selected="true"
                    no-data-text="No results to display"
                    :menu-props="videoStyleSelectProps"
                  >
                  </v-select>
                </div>
              </div>

              <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                <div :class="['vd-field', videoTopic ? 'vd-input-valid' : '']">
                  <label for="videoTopicTextArea" class="vd-dark-grey"
                    >Video Topic</label
                  >
                  <textarea id="videoTopicTextArea" v-model="videoTopic" />
                </div>
              </div>

              <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                <v-combobox
                  v-model="selectedVideoTags"
                  :class="[
                    'vd-field',
                    selectedVideoTags.length ? 'vd-input-valid' : '',
                  ]"
                  label="Video Tags"
                  :items="videoTagOptionArray"
                  :hide-selected="true"
                  :deletable-chips="true"
                  :loading="isVideoTagLoading"
                  placeholder="Select video tags"
                  persistent-placeholder
                  no-data-text="Start typing to search for tags"
                  multiple
                  chips
                  small-chips
                  :search-input.sync="videoTagsSearchInput"
                >
                  <span
                    slot="append-item"
                    v-intersect="handleVideoTagsLoadMore"
                  >
                  </span>
                </v-combobox>
              </div>

              <div class="col-12">
                <div
                  :class="[
                    'vd-field',
                    videoDescription.length ? 'vd-input-valid' : '',
                  ]"
                >
                  <label
                    for="id"
                    class="vd-dark-grey vd-margin-bottom-extra-small"
                    >Video Description</label
                  >
                  <textarea
                    id="videoDescriptionTextArea"
                    v-model="videoDescription"
                  />
                </div>
              </div>
            </div>
          </v-app>
        </div>
      </div>
    </div>

    <div
      slot="modal-footer"
      :class="[
        'vd-box-shadow vms-form-modal__footer',
        isMobileScreen
          ? 'vms-form-modal__footer--mobile vd-background-lightest-grey'
          : 'vd-background-white',
      ]"
    >
      <div
        :class="[
          isMobileScreen
            ? 'row no-gutters vd-padding-medium'
            : 'vd-text-align-right vd-padding-left-extra-large vd-padding-right-extra-large vd-padding-top-large vd-padding-bottom-large',
        ]"
      >
        <div
          :class="[
            isMobileScreen
              ? 'col-6 vd-text-align-center'
              : 'vd-display-inline-block vd-margin-right-small',
          ]"
        >
          <button class="vd-btn-medium" @click="handleCancel">Cancel</button>
        </div>
        <div
          :class="[
            isMobileScreen
              ? 'col-6 vd-text-align-center'
              : 'vd-display-inline-block',
          ]"
        >
          <button
            class="vd-btn-medium vd-btn-blue"
            :disabled="isSaveDisabled"
            @click="submitFormUpdate"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce, isEmpty, forEach, map, isEqual } from 'lodash'
import DefaultModal from '@components/modal/default-modal'
import Vuetify from 'vuetify'
import SweetAlert from '@plugins/sweet-alert'
import { ModelListSelect } from 'vue-search-select'
import VideoPlayer from '@components/video-player/video-player'
import DefaultLoader from '@components/loaders/default-loader'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    DefaultModal,
    VideoPlayer,
    ModelListSelect,
    DefaultLoader,
  },

  props: {
    videoId: {
      type: Number,
      required: true,
    },
  },

  vuetify: new Vuetify(),

  data() {
    return {
      selectedVideoTags: [],
      selectedVideoStyleTags: [],
      selectedVideoIndustry: null,
      videoTopic: '',
      videoDescription: '',
      isSaveDisabled: true,
      videoTagsCurrentPage: 1,
      videoTagsSearchInput: '',
    }
  },

  computed: {
    ...mapGetters({
      videoTagOptions: 'videos/videoTagOptions',
      videoIndustryOptions: 'videos/videoIndustryOptions',
      videoStyleOptions: 'videos/videoStyleOptions',
      isVideoTagLoading: 'videos/videoTagsLoading',
      isVideoStylesLoading: 'videos/videoStylesLoading',
      isVideoIndustriesLoading: 'videos/videoIndustriesLoading',
      isVideoInformationLoading: 'videos/videoInformationLoading',
      video: 'videos/selectedModalVideo',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isVideoInfoLoading() {
      return (
        this.isVideoIndustriesLoading ||
        this.isVideoStylesLoading ||
        this.isVideoInformationLoading
      )
    },

    videoStyleSelectProps() {
      return { offsetY: true, maxWidth: '300px' }
    },

    payload() {
      return {
        tags: this.selectedVideoTags,
        video_styles: this.selectedVideoStyleTags,
        industry_type: this.selectedVideoIndustry,
        topic: this.videoTopic,
        description: this.videoDescription,
        videoId: this.video.id,
      }
    },

    videoStyleOptionsFormatted() {
      const options = []

      if (!isEmpty(this.videoStyleOptions)) {
        forEach(this.videoStyleOptions, (option) => {
          options.push({
            text: option.label,
            value: option.id,
          })
        })
      }

      return options
    },

    videoTagOptionArray() {
      return map(this.videoTagOptions, 'name')
    },

    videoOptions() {
      return {
        controls: true,
        poster: this.video.video_thumbnail_url,
        sources: [
          {
            src: this.video.s3_url,
            type: 'video/mp4',
          },
        ],
        aspectRatio: '16:9',
        preload: true,
        userActions: {
          hotkeys: false,
        },
      }
    },
  },

  watch: {
    payload() {
      this.isSaveDisabled = !this.hasChanges()
    },

    // debounce is for delaying the function
    // this makes sure that it wont do an API call every value change.
    videoTagsSearchInput: debounce(function (newVal, oldVal) {
      if (newVal !== null && newVal !== oldVal) {
        this.videoTagsCurrentPage = 1
        this.getVideoTags({ searchTerm: newVal })
      }
    }, 500),

    video(video) {
      if (video) {
        this.setExistingValues()
      }
    },
  },

  mounted() {
    this.initVideoInformationModal({
      videoId: this.videoId,
      include: ApiCustomIncludes.videoInformation,
    })
  },

  methods: {
    ...mapActions({
      toggleVideoInformationShowModal: 'videos/toggleVideoInformationShowModal',
      updateVideoInformation: 'videos/updateVideoInformation',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      getVideoTags: 'videos/getVideoTags',
      initVideoInformationModal: 'videos/initializeVideoInformationModal',
    }),

    handleCancel() {
      if (!this.hasChanges()) {
        this.toggleVideoInformationShowModal()
        return
      }

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleVideoInformationShowModal()
        }
      })
    },

    setExistingValues() {
      const data = this.formatVideoData()
      this.selectedVideoTags = data.tags
      this.selectedVideoStyleTags = data.videoStyleTags
      this.selectedVideoIndustry = data.videoIndustry
      this.videoTopic = data.videoTopic
      this.videoDescription = data.videoDescription
    },

    submitFormUpdate() {
      this.updateVideoInformation(this.payload)
    },

    handleVideoTagsLoadMore() {
      this.videoTagsCurrentPage += 1
      this.getVideoTags({
        searchTerm: this.videoTagsSearchInput,
        page: this.videoTagsCurrentPage,
      })
    },

    formatVideoData() {
      return {
        tags: this.video.tags ? map(this.video.tags, 'name') : [],
        videoStyleTags: this.video.video_style_types
          ? map(this.video.video_style_types, 'id')
          : [],
        videoIndustry: this.video.industry_type_id || null,
        videoTopic: this.video.topic || '',
        videoDescription: this.video.description || '',
      }
    },

    hasChanges() {
      const data = this.formatVideoData()
      return (
        !isEqual(this.selectedVideoTags, data.tags) ||
        !isEqual(this.selectedVideoStyleTags, data.videoStyleTags) ||
        !isEqual(this.selectedVideoIndustry, data.videoIndustry) ||
        !isEqual(this.videoTopic, data.videoTopic) ||
        !isEqual(this.videoDescription, data.videoDescription)
      )
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/video-information';
@import '@styles/plugins/vue-search-select';
@import '@styles/plugins/vuetify';
</style>
