<template>
  <div
    :class="[
      'vms-dropdown vms-video__results--actions__dropdown vd-box-shadow vd-background-white vd-border-radius-6',
      isMobileScreen ? 'vms-video__results--actions__dropdown--mobile' : '',
    ]"
  >
    <div
      class="vms-dropdown__list vms-video__results--actions__dropdown--list-link vd-black vd-display-block vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
      @click.prevent="handleOpenVideo"
    >
      Open Video
    </div>

    <div @click.prevent="handleCopyPrivateUrl()">
      <clipboard-copy
        container-class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
        copy-text="Copy private video URL"
        copy-text-label-class="vd-black"
        copy-text-content-class="vd-black"
        :copy-content="videoDetails.video_url_private"
        :show-icon="false"
        :show-toast-alert="true"
        :success-message="clipboardCopySuccessMessage()"
      ></clipboard-copy>
    </div>
  </div>
</template>

<script>
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import { mapGetters } from 'vuex'

export default {
  components: {
    ClipboardCopy,
  },

  props: {
    videoDetails: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    clipboardCopySuccessMessage() {
      return `Successfully copied ${this.videoDetails.video_title} private video url!`
    },

    handleOpenVideo() {
      this.$emit('handle:vlp-click')
      window.open(this.videoDetails.video_url, '_blank')
    },

    handleCopyPrivateUrl() {
      this.$emit('handle:copy-private')
    },
  },
}
</script>
