<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vms-video__preview--title">
        <span
          :class="[
            'vms-video__preview--icons-privacy vd-margin-right-small',
            video.public_video
              ? 'vd-icon-video-public'
              : 'vd-icon-video-private',
          ]"
        ></span>
        <span class="vms-video__preview--title-text vd-h2">{{
          video.video_title | truncateString(65)
        }}</span>
      </div>

      <div class="vms-modal__header--close close vd-black">
        <span
          id="closeVideoMoreDetailsSpan"
          @click="toggleVideoPreviewDescriptionShowModal"
          >x</span
        >
      </div>
    </div>

    <div
      slot="modal-body"
      class="vms-video__preview--description-modal vd-padding-top-small vd-padding-right-large vd-padding-left-large vd-padding-bottom-large"
    >
      <div class="vd-h5 vd-margin-bottom-small">
        <span
          >Date created:
          {{ video.uploaded_timestamp | unixToFormat('D/M/YYYY') }}</span
        >
      </div>

      <div class="vd-margin-bottom-small">
        <p>{{ video.description }}</p>
      </div>

      <div class="vd-margin-bottom-small">
        <div v-if="video.project_price" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Project price:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                project_price: `${video.project_price}:${video.project_price}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_price | moneyFormat
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_production_hours"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Total production hours:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                project_production_hours: `${video.project_production_hours}:${video.project_production_hours}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_production_hours
            }}</span>
          </router-link>
        </div>

        <div v-if="video.product_type" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Product type:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'product_type[0]': video.product_type },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.product_type
            }}</span>
          </router-link>
        </div>

        <div v-if="hasVideoStyle" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Video style:</strong></span
          >
          <router-link
            v-for="(style, index) in video.style_types"
            :key="index"
            :to="{
              name: 'videos.library',
              query: { 'style_types[0]': style },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">
              <span v-if="index > 0" class="vd-margin-right-extra-small">,</span
              >{{ style }}
            </span>
          </router-link>
        </div>

        <div v-if="video.industry_type" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Video industry:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'industry_type[0]': video.industry_type },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.industry_type
            }}</span>
          </router-link>
        </div>

        <div v-if="video.office_name" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Office:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'office_name[0]': video.office_name },
            }"
          >
            <span
              class="vms-video__preview--description-info vd-black vd-black"
              >{{ video.office_name }}</span
            >
          </router-link>
        </div>

        <div v-if="video.project_producer" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Project manager:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'project_producer[0]': video.project_producer },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_producer
            }}</span>
          </router-link>
        </div>

        <div v-if="video.project_director" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Director:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'project_director[0]': video.project_director },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_director
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_account_manager"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Relationship manager:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                'project_account_manager[0]': video.project_account_manager,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_account_manager
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_production_coordinator"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Production coordinator:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                'project_production_coordinator[0]':
                  video.project_production_coordinator,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_production_coordinator
            }}</span>
          </router-link>
        </div>

        <div v-if="hasCrew" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Crew:</strong></span
          >
          <router-link
            v-for="(crew, index) in video.project_crew"
            :key="index"
            :to="{
              name: 'videos.library',
              query: { 'project_crew[0]': crew },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">
              <span v-if="index > 0" class="vd-margin-right-extra-small">,</span
              >{{ crew }}
            </span>
          </router-link>
        </div>

        <div
          v-if="video.project_shoot_crew_size"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Filming crew size:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                'project_shoot_crew_size[0]': video.project_shoot_crew_size,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_shoot_crew_size
            }}</span>
          </router-link>
        </div>

        <div v-if="video.project_platform" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Primary platform:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              name: 'videos.library',
              query: { 'project_platform[0]': video.project_platform },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_platform
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_target_audience"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Target audience:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                'project_target_audience_types[0]':
                  video.project_target_audience_types,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_target_audience_types
            }}</span>
          </router-link>
        </div>

        <div v-if="video.duration" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Duration:</strong></span
          >
          {{ videoDuration }}
        </div>

        <div v-if="video.aspect_ratio" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Aspect ratio:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'aspect_ratio[0]': video.aspect_ratio },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.aspect_ratio
            }}</span>
          </router-link>
        </div>

        <div v-if="video.resolution" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Resolution:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: { 'resolution[0]': video.resolution },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.resolution
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_preproduction_hours"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Pre-production hours:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                project_preproduction_hours: `${video.project_preproduction_hours}:${video.project_preproduction_hours}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_preproduction_hours
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_filming_hours"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Filming hours:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                project_filming_hours: `${video.project_filming_hours}:${video.project_filming_hours}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_filming_hours
            }}</span>
          </router-link>
        </div>

        <div
          v-if="video.project_editing_hours"
          class="vd-margin-bottom-extra-small"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Editing hours:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                project_editing_hours: `${video.project_editing_hours}:${video.project_editing_hours}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.project_editing_hours
            }}</span>
          </router-link>
        </div>

        <div v-if="hasDisbursements" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Disbursements:</strong></span
          >
          <router-link
            v-for="(disbursement, index) in video.project_disbursement"
            :key="index"
            :to="{
              name: 'videos.library',
              query: { 'project_disbursement[0]': disbursement },
            }"
          >
            <span
              class="vms-video__preview--description-info vd-black vd-margin-right-extra-small"
            >
              <span v-if="index > 0">, </span>{{ disbursement }}</span
            >
          </router-link>
        </div>

        <div v-if="video.company_target" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>House accounts target:</strong></span
          >
          <router-link
            :to="{
              name: 'videos.library',
              query: {
                company_target: `${video.company_target}:${video.company_target}`,
              },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">{{
              video.company_target | moneyFormat
            }}</span>
          </router-link>
        </div>

        <div v-if="hasYearlyObjective" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Company objectives:</strong></span
          >
          <router-link
            v-for="(objective, index) in video.office_yearly_objective_types"
            :key="index"
            :to="{
              name: 'videos.library',
              query: { 'office_yearly_objective_types[0]': objective },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">
              <span v-if="index > 0" class="vd-margin-right-extra-small">,</span
              >{{ objective }}
            </span>
          </router-link>
        </div>

        <div v-if="hasTags" class="vd-margin-bottom-extra-small">
          <span class="vd-margin-right-extra-small"
            ><strong>Video tags:</strong></span
          >
          <router-link
            v-for="(tag, index) in video.tags"
            :key="index"
            :to="{
              name: 'videos.library',
              query: { 'tags[0]': tag },
            }"
          >
            <span class="vms-video__preview--description-info vd-black">
              <span v-if="index > 0">, </span>{{ tag }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import DefaultModal from '@components/modal/default-modal'
import { size, parseInt, floor } from 'lodash'

export default {
  components: {
    DefaultModal,
  },

  mixins: [FiltersMixin],

  props: {
    video: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasDisbursements() {
      return size(this.video.project_disbursement) > 0
    },

    hasTags() {
      return size(this.video.tags) > 0
    },

    hasCrew() {
      return size(this.video.project_crew) > 0
    },

    hasVideoStyle() {
      return size(this.video.style_types) > 0
    },

    hasYearlyObjective() {
      return size(this.video.office_yearly_objective_types) > 0
    },

    videoDuration() {
      if (this.video.duration < 120) {
        return `${this.video.duration} seconds`
      } else {
        let label = `${floor(parseInt(this.video.duration) / 60)} minutes`
        let seconds = parseInt(this.video.duration) % 60

        if (seconds > 0) {
          label += ` ${seconds} seconds`
        }

        return label
      }
    },
  },

  methods: {
    ...mapActions({
      toggleVideoPreviewDescriptionShowModal:
        'videos/toggleVideoPreviewDescriptionShowModal',
    }),
  },
}
</script>
