<template>
  <div class="vms-breadcrumb vd-padding-top-10">
    <a id="viewHomePageLink" class="vd-a vd-icon-home" :href="homePageUrl"></a>

    <span
      class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
    >
      |
    </span>

    <router-link
      id="viewVideoLibraryPageLink"
      :to="{ name: 'videos.library' }"
      :class="['vd-a vd-padding-left-0 vd-padding-right-0']"
      >Video Library</router-link
    >

    <span
      class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
    >
      |
    </span>

    <span class="vd-black">{{ video.video_title | truncateString(68) }}</span>
  </div>
</template>

<script>
import AppConfig from '@configs/app-config'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    video: {
      type: Object,
      required: true,
    },
  },

  computed: {
    homePageUrl() {
      return `${AppConfig.vmsApiUrl}`
    },
  },
}
</script>
