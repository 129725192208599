<template>
  <video ref="videoPlayer" :class="['video-js', customPlayerClass]"></video>
</template>

<script>
import algoliaAnalytics from 'search-insights'
import { mapGetters } from 'vuex'

// Don't initialize analytics on test env.
if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

export default {
  props: {
    videoOptions: {
      type: Object,
      default() {
        return {}
      },
    },

    customPlayerClass: {
      type: String,
      default: '',
      required: false,
    },

    videoObjectId: {
      type: String,
      default: '',
      required: false,
    },

    videoQueryId: {
      type: String,
      default: '',
      required: false,
    },

    trackAlgoliaAnalytics: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      player: null,
    }
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),
  },

  beforeDestroy() {
    if (this.player) {
      window.videojs(this.$refs.videoPlayer).dispose()
    }
  },

  mounted() {
    this.player = window.videojs(this.$refs.videoPlayer, this.videoOptions)

    if (this.trackAlgoliaAnalytics) {
      let sendToAlgolia = true
      let videoObjectId = this.videoObjectId
      let videoQueryId = this.videoQueryId

      this.player.ready(function () {
        this.on('timeupdate', function () {
          if (this.currentTime() > 0 && sendToAlgolia) {
            algoliaAnalytics('convertedObjectIDs', {
              eventName: 'Item Watched',
              index: 'videos_index',
              objectIDs: [videoObjectId],
              queryID: videoQueryId,
            })

            sendToAlgolia = false
          }
        })
      })
    }
  },
}
</script>
