<template>
  <div
    :class="['vd-display-inline-block']"
    @click.prevent="handleUpdateVideoStar"
  >
    <text-popover
      :tooltip-text="toolTipText"
      :tooltip-icon="toolTipIcon"
      :tooltip-id="`markAsStar-${videoId}`"
      tooltip-placement="top"
      :custom-tooltip-class="customClass"
    ></text-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import algoliaAnalytics from 'search-insights'

if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

export default {
  components: {
    TextPopover,
  },
  props: {
    video: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    heroStarIcon: {
      type: String,
      required: false,
      default: 'vd-icon-star',
    },

    heroStarIconActive: {
      type: String,
      required: false,
      default: 'vd-icon-star__active',
    },

    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),

    toolTipIcon() {
      return `${this.heroStarIcon} ${
        this.video.is_starred ? this.heroStarIconActive : ''
      }`
    },

    toolTipText() {
      return this.video.is_starred
        ? 'Remove from hero videos'
        : 'Mark as hero video'
    },

    videoId() {
      return this.video.id ?? this.video.video_id
    },

    videoQueryId() {
      return this.video.__queryID
        ? this.video.__queryID
        : this.$route.query.queryId
    },
  },

  watch: {
    'loggedInUser.id': (val) => {
      if (val) {
        algoliaAnalytics('setUserToken', val.toString())
      }
    },
  },

  methods: {
    ...mapActions({
      updateVideoStar: 'videos/updateVideoStar',
    }),

    handleUpdateVideoStar() {
      const payload = {
        videoId: this.videoId,
        is_starred: this.video.is_starred ? 0 : 1,
      }

      if (this.video.objectID) {
        algoliaAnalytics('convertedObjectIDs', {
          eventName: 'Item hero clicked',
          index: 'videos_index',
          objectIDs: [this.video.objectID],
          userToken: this.loggedInUser.id.toString(),
          queryID: this.videoQueryId,
        })
      }

      this.updateVideoStar(payload).then(() => {
        this.$emit('star-updated')

        // force update to see if props has been updated
        this.$forceUpdate()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/hero-star';
</style>
